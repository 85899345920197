<main>
    <section class="pt-0">
      <div class="jumbotron jumbotron-fluid vh-75 d-flex align-items-center bg-dark text-white">
        <div class="bg" style="background-image:url(assets/images/workshops.jpg);"></div>
        <div class="container text-center">
          <div class="row justify-content-md-center">
        <div class="col-sm-12 col-md-12 col-12">
              <h1 class="headtext massive in-left">Workshops<br> & Events</h1>
              <p class="in-top o-7">Workshop for specific skills are tutored by industry experts in the specific skillset and the members of our club. These workshop cater to people who are looking to specialise in one style of graphic designing.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="container py-1">
        <div class="row" style="margin-top: 50px;">
          <div class="offset-sm-2 col-sm-8">
            <h3>Graphique Bootcamp</h3>
            <img data-aos="fade-right" class="w-100 img-fluid my-5" alt="Graphique Bootcamp" src="assets/images/bootcamp.jpg" style="box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.50);">
            <p data-aos="fade-left" style="margin-bottom: 20px;">
              Do quirky designs, appealing artworks and inventive ideas intrigue you? Are you one to perk up in domain like Graphic Design, Digital art, 3D & MoGraph and UI/UX Design? If you are fascinated by the subtle strokes of creativity in design, then we are giving you an oppurtunity to learn what we do at Graphique.

                A designer’s greatest attribute is their desire to learn and create. If you have an interest towards art and have a creative side, we welcome you to the Graphique Bootcamp! A fortnight of learning, practicing, designing, editing, modeling and more! We at Graphique are excited to help you broaden your creative horizons, and assist you in unleashing your inner designer. The Bootcamp is a four-week long training process where we provide tutorial videos, assist you with tasks. On successful completion, you will be a receive an official certification from us!
                </p>
                <div style="margin-bottom: 150px;" data-aos="fade-left">
                <div style="font-weight: 700;"><br>Registrations:</div>300+ (2021-22)
                <div style="font-weight: 700;"><br>Duration:</div>2 Months
                <div style="font-weight: 700;"><br>Participants:</div>1st Year Students
              </div>
            
            <h3>Chroma</h3>
            <img data-aos="fade-right" class="w-100 img-fluid my-5" alt="Chroma" src="assets/images/chroma.jpg" style="box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.50);"> 
            <p data-aos="fade-left">Chroma by Graphique is an online Art Contest exclusively for the freshers of NIT Trichy. Get your creative juices flowing out of bounds for a chance to reign as the best and win Rs. 2000 in prizes.

                Chroma is open to both traditional artists and digital artists. With the learning resources provided during registration, this is the perfect opportunity for amateur artists to venture into digital art and for experienced participants to hone their skills further.</p>
         
                <div style="margin-bottom: 100px;" data-aos="fade-left">
                  <div style="font-weight: 700;"><br>Registrations:</div>150+ (2021-22)
                  <div style="font-weight: 700;"><br>Duration:</div>15 days
                  <div style="font-weight: 700;"><br>Participants:</div>1st Year Students
                </div>
              </div>
          
        </div>
      </div>



      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <h3 class="mb-3" style="text-align: center; font-weight: 500; font-size: 32px;">Chroma '22 Winners</h3>
          </div>
        </div>      </div>
          <div class="container-fluid" data-aos="zoom-in-up">
            <div class="grid" >
                <div class="grid-sizer col-5"></div>
            <div *ngIf="workshops$ | async as workshops;">
                <div *ngFor="let workshop of workshops">
                  <div>
                <a  class="chroma grid-item col-6 col-sm-3"  ><img  alt={{workshop.creatorname}} src={{workshop.artwork}}>
                    <div class="caption p-3">
                      <div class="card-title">
                        <div class="name"  style="font-weight: 800;">{{workshop.creatorname}}</div>
                        <div class="title"  style="font-weight: 400;">{{workshop.prize}}</div>
                        <div class="title"  style="font-weight: 400;">{{workshop.category}}</div>
                      </div>
                    </div></a>
            </div>
            </div>
        </div>
        </div>
      </div>   
          <div class="container pb-8 mb-5">
            <div class="row">
              <div class="col-10 mb-3" style="text-align: center; margin-left: 9%;">
                  <p style="text-align: center;"><br> </p>      
              </div>
            </div>     
          </div> 

          

          <div class="container py-6">
            <div class="row">
              <div class="offset-sm-2 col-sm-8">
                <h3>Graphic by Graphique</h3>
                <img data-aos="fade-right" class="w-100 img-fluid my-5" alt="Graphic by Graphique" src="assets/images/gbg.jpg" style="box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.50);">
                <p data-aos="fade-left">A graphic design workshop. Get ready to experience an incredible opportunity to garner insight into how to channel the artist inside of you and will equip you with the skills required to bring your creative and artistic vision to life. Designed exclusively for first years, this will also serve as the first step on your path to becoming part of the official design team of Aaveg.</p>
                <div style="margin-bottom: 150px;" data-aos="fade-left">
                  <div style="font-weight: 700;"><br>Registrations:</div>350+ (2021-22)
                  <div style="font-weight: 700;"><br>Duration:</div>2 Days
                  <div style="font-weight: 700;"><br>Participants:</div>1st Year Students
                </div>

                <h3>Timeline Workshop</h3>
                <img data-aos="fade-right" class="w-100 img-fluid my-5" alt="Timeline Workshop" src="assets/images/timelineworkshop.jpg" style="box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.50);">
                <p data-aos="fade-left">Graphique brings you a curated and exciting workshop on Video Editing, Colour Correction, 3D Art and Motion Graphics. An excellent opportunity to add new skills to your skillset!
                </p>
                <div data-aos="fade-left" style="margin-bottom: 150px;">
                  <div style="font-weight: 700;"><br>Registrations:</div>550+ (2021-22)
                  <div style="font-weight: 700;"><br>Duration:</div>2 Days
                  <div style="font-weight: 700;"><br>Participants:</div>All NIT Trichy Students
                </div>

                <h3>NIT ConclaveX Designathon</h3>
                <img data-aos="fade-right" class="w-100 img-fluid my-5" alt="Graphic by Graphique" src="assets/images/designathon.jpg" style="box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.50);">
                <p data-aos="fade-left">It is a 24-hour online hackathon for designers and developers, providing a platform to compete against the best design and developing minds across the country in a fast-paced environment and test your design thinking practices. Hone your design and developing skills by responding to evolving needs and solving challenges in our community and beyond to create resilience, hope, and inspiration.</p>
                <div data-aos="fade-left">
                  <div style="font-weight: 700;"><br>Registrations:</div>TBA
                  <div style="font-weight: 700;"><br>Duration:</div>24 hours
                  <div style="font-weight: 700;"><br>Participants:</div>Open to all NIT students
                </div>
              </div>
            </div>
          </div>
      </section>
  </main>
