import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-redirect-for-samsung',
  templateUrl: './redirect-for-samsung.component.html',
  styleUrls: ['./redirect-for-samsung.component.css']
})
export class RedirectForSamsungComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
