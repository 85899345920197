<div id="snackbar"><b></b><a style="color:bisque;" href="https://hameedibrh.com" target="_blank">Hameed</a> & <a style="color:bisque;" href="https://yagneshlp.me" target="_blank">YLP</a> 😉</div>

  <footer style="z-index: 1;">
    <div class="container-fluid p-5">
      <div class="d-flex justify-content-between align-items-center">
        <div class="social"><a class="trigger">Follow</a>
          <ul class="social-nav">
            <li> <a target="_blank" href="https://nitt.wiki/index.php/Graphique">NITT WIKI</a></li>
            <li> <a target="_blank" href="https://www.facebook.com/graphique.nitt">Facebook</a></li>
            <li> <a target="_blank" href="https://www.youtube.com/graphiquenitt">YouTube</a></li>
            <li> <a target="_blank" href="https://www.behance.net/graphiquenitt">Behance</a></li>
            <li> <a target="_blank" href="https://www.instagram.com/graphique.nitt/">Instagram</a></li>
          </ul>
        </div>
        <div (click)="showSnack()" class="copy" style="text-align: center;">2022 &copy; Graphique. All rights reserved. <br>Made with 🔥 by <a>Graphique WebOps Team.</a></div>
        <div class="row">
      <div class="col-lg-8 offset-lg-2" >
          <div style="text-align: center; margin: 0 auto;">
          <a (click)="scrollToTop()" style="font-family: Montserrat; color: white; font-size: 18px;"><img src="https://img.icons8.com/external-those-icons-fill-those-icons/24/000000/external-up-arrows-those-icons-fill-those-icons-1.png"/></a>
      </div>
        <div class="border-bottom my-5"></div>
      </div>
    </div>
        
      </div>
    </div>
  </footer>