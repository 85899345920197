<main>
  <div class="jumbotron jumbotron-fluid vh-75 d-flex align-items-center bg-dark text-white">
    <div class="bg" style="background-image:url(assets/images/aboutus.jpg);"></div>
    <div class="container text-center">
      <div class="row justify-content-md-center">
        <div class="col-sm-12 col-md-12 col-12">
          <h1 class="thala massive in-left" >Wallpapers</h1>
          <p class="in-top o-7">It’s time for us to change your wallpaper! Check out our 100+ Graphique Wallpapers created by our club members.</p>
        </div>
      </div>
    </div>
  </div>
    <section>
      
  
      <div class="container my-5 py-3">
        <div class="row justify-content-md-center text-center">
          <div class="col-sm-8">
            <h3>*Before you download our wallpapers*</h3>
            <p>Credit is so much important for an artist. 💯

              But we have not added our Graphique watermark in any wallpaper due to our followers' request. 🤝
              
              Will you promise us to share your Home screen/Lock Screen with our wallpaper and tag us? It will be really motivating for us! </p>
          </div>
        </div>
      </div>
      <div class="container-fluid" data-aos="zoom-in-up" *ngIf="wallpapers$ | async as wallpapers;">
        <div class="grid gallery" >
          <div class="grid-sizer"></div>
          <div class="row- mx-0 pb-5 " >
            <div class="col-sm-12 col-12 px-0" >
          <div  style="margin: 0 auto; text-align: center;" class="card bg-dark text-white hoverable grid-item col-6 col-sm-3" *ngFor="let wallpaper of wallpapers"><img class="card-img" alt={{wallpaper.creatorname}} src={{wallpaper.wallpaper}}>
            <div style="margin: 0 auto; text-align: center; height: 20%;"class="card-img-overlay" >
              <div class="card-title">
                <div class="name">{{wallpaper.creatorname}}</div>
                <div class="title">{{wallpaper.username}}</div>
                
              </div>
            </div>
            <div style="text-align: center; margin: 0 auto; background: rgb(0, 0, 0); padding: 12px;
            ">
            <a href={{wallpaper.wallpaper}} target="_blank" style="font-family: Montserrat; color: white; font-size: 12px; "><i class="fa fa-download"></i> DOWNLOAD</a>
        </div>
          </div>
          
        </div>
          </div>
      
        </div>
      </div>
     
    </section>
  </main>
