<main>
  <div (click)="red()" id="snackbar"><b></b>Tap here to see "{{ proj }}"</div>

    <div class="jumbotron jumbotron-fluid vh-75 d-flex align-items-center bg-dark text-white">
        <div class="bg" style="background-image:url(assets/images/projects.jpg);"></div>
        <div class="container text-center">
          <div class="row justify-content-md-center">
            <div class="col-sm-12 col-md-12 col-12">
              <h1 class="massive in-left">Our Projects</h1>
              <p class="in-top o-7">We have done many interesting projects so far! Have a look at it :)</p>
            </div>
          </div>
        </div>
      </div>
    <section>
      <div class="container-fluid py-6"> 
          
        <h2> <span class="d-block in-left">"Design is so simple.</span><span class="d-block in-top">That's why its complicated." <img src="https://img.icons8.com/external-justicon-lineal-color-justicon/28/000000/external-emoji-emoji-justicon-lineal-color-justicon-4.png"/></span></h2>
      </div>
      <div class="container-fluid" *ngIf="projects$ | async as projects;">
        <div class="grid">
          <div class="grid-sizer col-1"></div>
          <div *ngFor="let project of projects">
          <a *ngIf="project.name === 'Festember' || project.name === 'Pragyan' || project.name === 'Orientation'" class="grid-item col-12 col-md-4" ><img alt={{project.name}} src={{project.cover}}>
            <div (click)="showSnack(project.link, project.name)" class="caption p-3">
              <div class="title"><span style="font-weight: bold; font-size: larger;">{{project.name}}<br></span><span style="font-style: italic; font-weight: lighter;">{{project.date}}<br></span><span>{{project.description}}</span></div>
            </div></a>
        </div> 
            
            
        <div *ngFor="let project of projects">
            <a *ngIf="project.name !== 'Festember' && project.name !== 'Pragyan' && project.name !== 'Orientation'" class="grid-item col-12 col-md-3"><img alt={{project.name}} src={{project.cover}}>
                <div (click)="showSnack(project.link, project.name)" class="caption p-3">
                    <div class="title"><span style="font-weight: bold; font-size: larger;">{{project.name}}<br></span><span style="font-style: italic; font-weight: lighter;">{{project.date}}<br></span><span>{{project.description}}</span></div>
                </div></a>
          </div>

        </div>
      </div>
      <div class="container my-5 py-6">
        <div class="row justify-content-md-center text-center">
          <div class="col-sm-5">
            <br><br><br>
            <h4>Stay tuned for our upcoming projects!</h4>
            <p>We are uploading all our projects <a href="https://instagram.com/graphique.nitt" target="_blank">Instagram</a> and <a href="https://behance.net/graphiquenitt" target="_blank">Behance</a>. So do follow us there!</p>
          </div>
        </div>
      </div>
    </section>
  </main>
