<main>
    <section class="pt-0">
        <div class="jumbotron jumbotron-fluid vh-75 d-flex align-items-center bg-dark text-white">
          <div class="bg" style="background-image:url(assets/images/certificatepage.jpg);"></div>
          <div class="container text-center">
            <div class="row justify-content-md-center">
              <div class="col-sm-7">
                <h1 class="massive in-left" style="font-size: 50px; letter-spacing:normal; line-height: 50px;">Certificate Verification</h1>
                <p class="in-top o-7"> Graphique issues certificates for various workshops & competitions. Each certificate will have a unique ID that can be verified on this website.</p>

              
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="container py-1">
        <div class="row" style="margin-top: 50px;">
            <div class="offset-sm-2 col-sm-8">
                <div class="searchingSpinner" *ngIf="isSearching">            
                    <div class="searchSpinnerBox">
                        <span style="z-index: 100;">Searching</span>
                        <div class="blue-orbit leo"></div>              
                        <div class="green-orbit leo"></div>                
                        <div class="red-orbit leo"></div>
                      </div>
                      
                </div>
    <div class="resultFailure" *ngIf="!isCertificateValid && !isSearching && certID">
        <h2><fa-icon [icon]="['fas', 'times']" class="redCross"></fa-icon>  This is not a valid certificate. <img src="https://img.icons8.com/emoji/48/000000/triangular-flag.png"/></h2>
        <a href="/verify"><button><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon> Go back</button></a>
    </div>
    <div class="resultSuccess" *ngIf="isCertificateValid">
        <div class="certificateImage" >                                     
            <div class="spinner-box" *ngIf="!certImageLoaded">
                <span class="loadingText"><br>Loading Certificate </span>

                <div class="loading"></div>
            </div>
            <div class="certificateContainer">
                <img crossorigin="anonymous" id="certImg" [src]=certificateImage ng-hide="certImageLoaded" (load)="certImageLoadedHandler()">
            </div>
            
        </div> 
        <div class="details">
            <h2><fa-icon [icon]="['fas', 'check']" class="greeenCheck"></fa-icon>  This is a valid certificate. <img src="https://img.icons8.com/emoji/48/000000/check-mark-button-emoji.png"/></h2>
            <p class="subtitle">This certificate was issued to {{name}} ({{rollnumber}}) for {{eventname}}.</p>
            <div class="actions">
                <div class="download">
                    <button [disabled]="isFileDownloading" (click)="download()"><fa-icon [icon]="['fas', 'download']"></fa-icon> Download</button>
                    <a [disabled]="isFileDownloading" href="/verify"><button><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon> Search another</button></a>

                </div>
                <!-- <div class="share">
                    <button><fa-icon [icon]="['fas', 'download']"></fa-icon></button>
                    <button><fa-icon [icon]="['fas', 'download']"></fa-icon></button>
                    <button><fa-icon [icon]="['fas', 'download']"></fa-icon></button>
                </div> -->
            </div>
        </div>     
    </div>
    </div>
</div>
</div>
  
</main>